// import logo from './logo.svg';
// import buildinglogo from './image/building.png';
// import logo from './image/logos.png';
// import footprint from './image/footprint.png';
// import minus from './image/minus.png';
// import magic from './image/magic.png';
// import './App.css';
// import canvasjs from './js/canvas.txt'
// import React from 'react';
import React from "react";
import "./App.css";
import Dashboard from "./components/dashboard";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
  <BrowserRouter>
    <Dashboard>
    </Dashboard>
  </BrowserRouter>

  );
}

export default App;
