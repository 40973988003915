import React, { useEffect, useState } from "react";
import "../App.css";
import Swatch from "./swatch";
import rough from "roughjs/bundled/rough.esm";
import {
  createElement,
  adjustElementCoordinates,
  cursorForPosition,
  resizedCoordinates,
  midPointBtw,
  getElementAtPosition,
} from "./element";
import RainbowText from "react-rainbow-text";
import Rainbow from "rainbowvis.js";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
// import { List } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
// import Item from '@mui/material/Item';
import $ from "jquery";
import CreateCard from "./createcard";
import Createlib from "./createlib";
import { Paper } from "@mui/material";

function Canvas() {
  const [points, setPoints] = useState([]);
  const [path, setPath] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [elements, setElements] = useState([]);
  const [action, setAction] = useState("none");
  const [toolType, setToolType] = useState("brush");
  const [selectedElement, setSelectedElement] = useState(null);
  const [colorWidth, setColorWidth] = useState({
    hex: "#000",
    hsv: {},
    rgb: {},
  });
  const [width, setWidth] = useState(1);
  const [shapeWidth, setShapeWidth] = useState(1);
  const [popped, setPopped] = useState(false);
  const [canvasoffsetX, setCanvasOffsetX] = useState(1);
  const [canvasoffsetY, setCanvasOffsetY] = useState(1);
  const [currentsentenceid, setCurrentSentenceid] = React.useState(0);

  useEffect(() => {
    const canvas = document.getElementById("canvas");
    const $preview = document.getElementById("preview");
    const context = canvas.getContext("2d");
    setCanvasOffsetX(canvas.offsetLeft);
    setCanvasOffsetY(canvas.offsetTop);
    context.lineCap = "round";
    context.lineJoin = "round";

    context.save();
    context.globalCompositeOperation = "source-over";
    context.drawImage(
      $preview,
      0,
      0,
      $preview.width,
      $preview.height,
      0,
      0,
      canvas.width,
      canvas.height
    );

    const drawpath = () => {
      path.forEach((stroke, index) => {
        const steps = 50;
        let lastpoint = stroke[0];
        stroke.forEach((point, i) => {
          context.beginPath();
          context.strokeStyle = point.newColour;
          context.lineWidth = point.newLinewidth;

          // var midPoint = midPointBtw(point.clientX, point.clientY);

          let dx = point.clientX - lastpoint.clientX;
          let dy = point.clientY - lastpoint.clientY;
          context.moveTo(lastpoint.clientX, lastpoint.clientY);
          for (var i = -8; i < steps; i++) {
            var xx = lastpoint.clientX + (dx * i) / (steps - 1);
            var yy = lastpoint.clientY + (dy * i) / (steps - 1);
            // context.lineTo(xx,yy);
          }
          context.quadraticCurveTo(
            lastpoint.clientX,
            lastpoint.clientY,
            point.clientX,
            point.clientY
          );
          // context.lineTo(point.clientX, point.clientY);
          lastpoint = point;
          context.stroke();
          context.closePath();
        });
        context.save();
      });
    };

    if (toolType === "eraser" && popped === true) {
      context.clearRect(0, 0, canvas.width, canvas.height);
      setPopped(false);
    }

    const roughCanvas = rough.canvas(canvas);

    if (path !== undefined) drawpath();

    context.lineWidth = shapeWidth;

    elements.forEach(({ roughElement }) => {
      context.globalAlpha = "1";
      //console.log(roughElement);
      context.strokeStyle = roughElement.options.stroke;
      roughCanvas.draw(roughElement);
    });

    return () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
    };
  }, [popped, elements, path, width, shapeWidth, toolType]);

  const clear = () => {
    const $preview = document.querySelector("#preview");
    const pctx = $preview.getContext("2d");
    pctx.clearRect(0, 0, $preview.width, $preview.height);
  };

  const toSpectrum = (points) => {
    const length = points.length;
    var rainbow = new Rainbow();
    rainbow.setNumberRange(0, length - 1);
    let newpoints = points;
    return newpoints.map((p, index) => {
      p.newColour = "#" + rainbow.colourAt(index);
      p.colour = "#" + rainbow.colourAt(index);
      return p;
    });
  };

  const updateElement = (
    index,
    x1,
    y1,
    x2,
    y2,
    toolType,
    strokeWidth,
    strokeColor
  ) => {
    const updatedElement = createElement(
      index,
      x1,
      y1,
      x2,
      y2,
      toolType,
      strokeWidth,
      strokeColor
    );
    const elementsCopy = [...elements];
    elementsCopy[index] = updatedElement;
    setElements(elementsCopy);
  };

  const checkPresent = (clientX, clientY) => {
    if (path === undefined) return;
    var newPath = path;
    path.forEach((stroke, index) => {
      stroke.forEach((point, i) => {
        if (
          clientY < point.clientY + 10 &&
          clientY > point.clientY - 10 &&
          clientX < point.clientX + 10 &&
          clientX > point.clientX - 10
        ) {
          //console.log("Popped");
          newPath.splice(index, 1);
          setPopped(true);
          setPath(newPath);
          return;
        }
      });
    });
    const newElements = elements;
    newElements.forEach((ele, index) => {
      if (
        clientX >= ele.x1 &&
        clientX <= ele.x2 &&
        clientY >= ele.y1 &&
        clientY <= ele.y2
      ) {
        console.log("Popped....");
        newElements.splice(index, 1);
        setPopped(true);
        setElements(newElements);
      }
    });
  };

  const handleMouseDown = (e) => {
    let { clientX, clientY } = e;
    clientX = clientX - canvasoffsetX;
    clientY = clientY - canvasoffsetY;
    const canvas = document.getElementById("canvas");
    const context = canvas.getContext("2d");

    if (toolType === "selection") {
      const element = getElementAtPosition(clientX, clientY, elements);
      if (element) {
        const offsetX = clientX - element.x1;
        const offsetY = clientY - element.y1;
        setSelectedElement({ ...element, offsetX, offsetY });
        if (element.position === "inside") {
          setAction("moving");
        } else {
          setAction("resize");
        }
      }
    } else if (toolType === "eraser") {
      setAction("erasing");

      checkPresent(clientX, clientY);
    } else {
      const id = elements.length;
      if (toolType === "pencil" || toolType === "brush") {
        setAction("sketching");
        setIsDrawing(true);

        const newColour = colorWidth.hex;
        const newLinewidth = width;
        const transparency = toolType === "brush" ? "0.1" : "1.0";
        const newEle = {
          clientX,
          clientY,
          newColour,
          newLinewidth,
          transparency,
          currentsentenceid,
        };
        setPoints((state) => [...state, newEle]);

        context.strokeStyle = newColour;
        context.lineWidth = newLinewidth;
        context.lineCap = 5;
        context.moveTo(clientX, clientY);
        context.beginPath();
      } else {
        setAction("drawing");
        const newColour = colorWidth.hex;
        const newWidth = shapeWidth;
        const element = createElement(
          id,
          clientX,
          clientY,
          clientX,
          clientY,
          toolType,
          newWidth,
          newColour
        );

        setElements((prevState) => [...prevState, element]);
        setSelectedElement(element);
      }
    }
  };

  const handleMouseMove = (e) => {
    const canvas = document.getElementById("canvas");
    const context = canvas.getContext("2d");
    let { clientX, clientY } = e;
    clientX = clientX - canvasoffsetX;
    clientY = clientY - canvasoffsetY;
    if (toolType === "selection") {
      const element = getElementAtPosition(clientX, clientY, elements);
      e.target.style.cursor = element
        ? cursorForPosition(element.position)
        : "default";
    }
    if (action === "erasing") {
      checkPresent(clientX, clientY);
    }
    if (action === "sketching") {
      if (!isDrawing) return;
      const colour = points[points.length - 1].newColour;
      const linewidth = points[points.length - 1].newLinewidth;
      const transparency = points[points.length - 1].transparency;
      const newEle = {
        clientX,
        clientY,
        colour,
        linewidth,
        transparency,
        currentsentenceid,
      };

      setPoints((state) => [...state, newEle]);
      var midPoint = midPointBtw(clientX, clientY);
      context.quadraticCurveTo(clientX, clientY, midPoint.x, midPoint.y);
      context.lineTo(clientX, clientY);
      context.stroke();
    } else if (action === "drawing") {
      const index = elements.length - 1;
      const { x1, y1 } = elements[index];
      elements[index].strokeColor = colorWidth.hex;
      elements[index].strokeWidth = shapeWidth;
      updateElement(
        index,
        x1,
        y1,
        clientX,
        clientY,
        toolType,
        shapeWidth,
        colorWidth.hex
      );
    } else if (action === "moving") {
      const {
        id,
        x1,
        x2,
        y1,
        y2,
        type,
        offsetX,
        offsetY,
        shapeWidth,
        strokeColor,
      } = selectedElement;
      const offsetWidth = x2 - x1;
      const offsetHeight = y2 - y1;
      const newX = clientX - offsetX;
      const newY = clientY - offsetY;
      updateElement(
        id,
        newX,
        newY,
        newX + offsetWidth,
        newY + offsetHeight,
        type,
        shapeWidth,
        strokeColor
      );
    } else if (action === "resize") {
      const { id, type, position, ...coordinates } = selectedElement;
      const { x1, y1, x2, y2 } = resizedCoordinates(
        clientX,
        clientY,
        position,
        coordinates
      );
      updateElement(id, x1, y1, x2, y2, type, shapeWidth, colorWidth.hex);
    }
  };
  const handleMouseUp = () => {
    if (action === "resize") {
      const index = selectedElement.id;
      const { id, type, strokeWidth, strokeColor } = elements[index];
      const { x1, y1, x2, y2 } = adjustElementCoordinates(elements[index]);
      updateElement(id, x1, y1, x2, y2, type, strokeWidth, strokeColor);
    } else if (action === "drawing") {
      const index = selectedElement.id;
      const { id, type, strokeWidth } = elements[index];
      const { x1, y1, x2, y2 } = adjustElementCoordinates(elements[index]);
      updateElement(id, x1, y1, x2, y2, type, strokeWidth, colorWidth.hex);
    } else if (action === "sketching") {
      const canvas = document.getElementById("canvas");
      const context = canvas.getContext("2d");
      context.closePath();
      let element = toSpectrum(points);

      setPoints([]);
      setPath((prevState) => [...prevState, element]); //tuple
      setIsDrawing(false);
    }
    setAction("none");
  };

  const [caption, setCaption] = React.useState("Input your descriptions.");
  const [sentences, setSentences] = React.useState([]);

  const handleChange = (event) => {
    setCaption(event.target.value);
    setSentences(caption.split("."));
  };

  const onSentenceClick = (index) => {
    setCurrentSentenceid(index);
  };

  const prediction = () => {
    const canvas = document.getElementById("canvas");
    $.ajax({
      type: "POST",
      url: "http://" + document.location.hostname + ":5000/predict",
      data: JSON.stringify({
        sentences: sentences,
        traces: path.map((trace, index0) => {
          return trace.map((point, index1) => {
            return {
              x: point.clientX / canvas.width,
              y: point.clientY / canvas.height,
            };
          });
        }),
      }),
      contentType: "application/json",
      dataType: "json",
      success: (data) => {
        const SIZE = 256;
        const context = canvas.getContext("2d");
        let $preview = document.querySelector("#preview");
        let pctx = $preview.getContext("2d");
        let res = Uint8ClampedArray.from(data["data"], (x) => x);
        $preview.width = 0;
        $preview.width = SIZE;
        $preview.height = SIZE;
        let resImgData = new ImageData(res, SIZE, SIZE);
        pctx.putImageData(resImgData, 0, 0);
        context.drawImage(
          $preview,
          0,
          0,
          $preview.width,
          $preview.height,
          0,
          0,
          canvas.width,
          canvas.height
        );
      },
    });
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-evenly"
      style={{ height: "80vh", margin: 0, padding: 0 }}
      spacing={20}
    >
      <Stack spacing={5} xs={4} lg={4} xl={4} style={{ width: "30vw" }}>
        <Grid item>
          <TextField
            id="outlined-multiline-flexible"
            label="Caption"
            multiline
            maxRows={15}
            minRows={5}
            value={caption}
            fullWidth={true}
            onChange={handleChange}
          />
        </Grid>
        <Card maxWidth="20vw" minWidth="20vw">
          <CardContent>
            <Typography variant="h5" component="div">
              Confirmed Sentences
            </Typography>
            <List>
              {sentences.map((sent, index) => (
                <ListItemButton
                  key={index}
                  onClick={() => onSentenceClick(index)}
                >
                  <ListItemText>
                    <RainbowText lightness={0.5} saturation={1}>
                      {sent + "."}
                    </RainbowText>
                  </ListItemText>
                </ListItemButton>
              ))}
            </List>
          </CardContent>
        </Card>

        <Button
          variant="contained"
          onClick={() => {
            prediction();
          }}
        >
          Generate
        </Button>

        <Createlib />
      </Stack>
            <Stack spacing={5} xs={4} lg={4} xl={4} style={{ width: "30vw" }}>
        <Paper elevation={3} style={{ background: "white" }} square>
          <canvas
            id="canvas"
            className="App"
        //     width={'100%'}
        //     height={'100%'}
            width={"800px"}
            height={"800px"}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onTouchStart={(e) => {
              var touch = e.touches[0];
              handleMouseDown({
                clientX: touch.clientX,
                clientY: touch.clientY,
              });
            }}
            onTouchMove={(e) => {
              var touch = e.touches[0];
              handleMouseMove({
                clientX: touch.clientX,
                clientY: touch.clientY,
              });
            }}
            onTouchEnd={handleMouseUp}
          >
            Canvas
          </canvas>
        </Paper>
	        <Button
          variant="contained"
          onClick={() => {
            prediction();
          }}
        >
          Variant
        </Button>
      </Stack>
      <Grid containere item xs={1} justifyContent="center">
        <Swatch
          toolType={toolType}
          setToolType={setToolType}
          width={width}
          setWidth={setWidth}
          setElements={setElements}
          setColorWidth={setColorWidth}
          setPath={setPath}
          colorWidth={colorWidth}
          setShapeWidth={setShapeWidth}
          clear={clear}
        />
      </Grid>
      <canvas id="preview" width="100" height="100" hidden></canvas>
    </Grid>
  );
}

export default Canvas;
