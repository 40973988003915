import React, { useCallback, useState, useRef } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  useReactFlow,
  addEdge,
  MiniMap,
  Controls,
  ReactFlowProvider,
  useStoreApi,
} from "reactflow";
import styled, { ThemeProvider } from "styled-components";

import { nodes as initialNodes, edges as initialEdges } from "./nodes-edges";
import { darkTheme, lightTheme } from "./theme";
import CustomNode from "./CustomNode";

import "reactflow/dist/style.css";

const nodeTypes = {
  custom: CustomNode,
};

const ReactFlowStyled = styled(ReactFlow)`
  background-color: ${(props) => props.theme.bg};
`;

const MiniMapStyled = styled(MiniMap)`
  background-color: ${(props) => props.theme.bg};

  .react-flow__minimap-mask {
    fill: ${(props) => props.theme.minimapMaskBg};
  }

  .react-flow__minimap-node {
    fill: ${(props) => props.theme.nodeBg};
    stroke: none;
  }
`;

const ControlsStyled = styled(Controls)`
  button {
    background-color: ${(props) => props.theme.controlsBg};
    color: ${(props) => props.theme.controlsColor};
    border-bottom: 1px solid ${(props) => props.theme.controlsBorder};

    &:hover {
      background-color: ${(props) => props.theme.controlsBgHover};
    }

    path {
      fill: currentColor;
    }
  }
`;

const fitViewOptions = {
  padding: 3,
};

let id = 1;
const getId = () => `${id++}`;

const FlowWOTheme = () => {
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { project } = useReactFlow();
  const store = useStoreApi();
  const getDataById = (id) => {
    const { nodeInternals } = store.getState();
    const nodes = Array.from(nodeInternals).map(([, node]) => node);
    const node = nodes.find((n) => n.id === id);
    return node?.data;
  };
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains("react-flow__pane");

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
        const id = getId();
        const data = getDataById(connectingNodeId.current);
        const newNode = {
          id,
          type: "custom",
          // we are removing the half of the node width (75) to center the new node
          position: project({
            x: event.clientX - left - 75,
            y: event.clientY - top,
          }),
          data: { ...data, label: `Node ${id}` },
        };

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) =>
          eds.concat({ id, source: connectingNodeId.current, target: id })
        );
      }
    },
    [project]
  );
  
  const { setCenter, zoomIn, zoomTo } = useReactFlow();
  const onNodeClick = (event, nodeclick) => {
    // console.log(position);
    const { nodeInternals } = store.getState();
    const nodes = Array.from(nodeInternals).map(([, node]) => node);
    const node = nodes.find((n) => n.id === nodeclick.id);
    const x = node.position.x + node.width / 2;
    const y = node.position.y + node.height / 2;
    const zoom = 3;
    setCenter(x, y, { zoom, duration: 1000 });
    // console.log(xPos, yPos);
    // setCenter(xy.x, xy.y);
    // zoomIn({ duration: 800 });
    // console.log(node.position, xy);

    // setViewport({ ...xy, zoom: 3 }, { duration: 800 });
  };

  return (
    <div
      className="wrapper"
      ref={reactFlowWrapper}
      style={{ height: "inherit" }}
    >
      <ReactFlowStyled
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onNodeClick={onNodeClick}
        nodeTypes={nodeTypes}
        fitView
        fitViewOptions={fitViewOptions}
      >
        <MiniMapStyled />
        <ControlsStyled />
      </ReactFlowStyled>
    </div>
  );
};

const Flow = () => {
  const [mode, setMode] = useState("dark");
  const theme = mode === "light" ? lightTheme : darkTheme;

  const toggleMode = () => {
    setMode((m) => (m === "light" ? "dark" : "light"));
  };

  return (
    <ReactFlowProvider>
      <ThemeProvider theme={theme}>
        <button
          onClick={toggleMode}
          style={{ position: "absolute", zIndex: 100, left: 100, top: 100 }}
        >
          switch mode
        </button>
        <FlowWOTheme />
      </ThemeProvider>
    </ReactFlowProvider>
  );
};

// import React, { useCallback, useRef } from 'react';
// import ReactFlow, {
//   useNodesState,
//   useEdgesState,
//   addEdge,
//   useReactFlow,
//   ReactFlowProvider,
// } from 'reactflow';
// import 'reactflow/dist/style.css';
// //

// const initialNodes = [
//   {
//     id: '0',
//     type: 'input',
//     data: { label: 'Node' },
//     position: { x: 0, y: 50 },
//   },
// ];

// let id = 1;
// const getId = () => `${id++}`;

// const fitViewOptions = {
//   padding: 3,
// };

// const AddNodeOnEdgeDrop = () => {
//   const reactFlowWrapper = useRef(null);
//   const connectingNodeId = useRef(null);
//   const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
//   const [edges, setEdges, onEdgesChange] = useEdgesState([]);
//   const { project } = useReactFlow();
//   const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

//   const onConnectStart = useCallback((_, { nodeId }) => {
//     connectingNodeId.current = nodeId;
//   }, []);

//   const onConnectEnd = useCallback(
//     (event) => {
//       const targetIsPane = event.target.classList.contains('react-flow__pane');

//       if (targetIsPane) {
//         // we need to remove the wrapper bounds, in order to get the correct position
//         const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
//         const id = getId();
//         const newNode = {
//           id,
//           // we are removing the half of the node width (75) to center the new node
//           position: project({ x: event.clientX - left - 75, y: event.clientY - top }),
//           data: { label: `Node ${id}` },
//         };

//         setNodes((nds) => nds.concat(newNode));
//         setEdges((eds) => eds.concat({ id, source: connectingNodeId.current, target: id }));
//       }
//     },
//     [project]
//   );

//   return (
//     <div className="wrapper" ref={reactFlowWrapper}>
//       <ReactFlow
//         nodes={nodes}
//         edges={edges}
//         onNodesChange={onNodesChange}
//         onEdgesChange={onEdgesChange}
//         onConnect={onConnect}
//         onConnectStart={onConnectStart}
//         onConnectEnd={onConnectEnd}
//         fitView
//         fitViewOptions={fitViewOptions}
//         />
//      </div>
//   );
// };

// const Flow= () => (
//   <ReactFlowProvider>
//     <AddNodeOnEdgeDrop />
//   </ReactFlowProvider>
// );

export default Flow;
