export const nodes = [
  {
    id: '0',
    type: 'custom',
    data: { label: 'Node 0', image: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e', caption: 'Input your description' },
    position: { x: 0, y: 0 },
  },

];

export const edges = [

];
