import * as React from "react";

import { CardActionArea, Grid } from "@mui/material";
import { Grid3x3 } from "@mui/icons-material";
import CreateCard from "./createcard";

export default function Createlib() {
  return (
    <Grid container>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <CreateCard></CreateCard>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <CreateCard></CreateCard>
      </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
        <CreateCard></CreateCard>
      </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
        <CreateCard></CreateCard>
      </Grid>
    </Grid>
  );
}
