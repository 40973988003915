// import { ChatGPTAPIBrowser } from 'chatgpt'



const Chat = () => {
//   const example = async (email, password) => {
//   // use puppeteer to bypass cloudflare (headful because of captchas)
//   const api = new ChatGPTAPIBrowser({
//     email: email,
//     password: password,
//   })

//   await api.initSession()

//   const result = await api.sendMessage('Hello World!')
//   console.log(result.response)
// }
    return (
        <div></div>
    );
}
export default Chat;