import React, { memo } from "react";
import { Handle, useReactFlow, NodeToolbar, Position } from "reactflow";
import styled from "styled-components";
import { CardMedia } from "@mui/material";
import { useCallback } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import $ from "jquery";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const Node = styled.div`
  padding: 10px 20px;
  border-radius: 5px;
  background: ${(props) => props.theme.nodeBg};
  color: ${(props) => props.theme.nodeColor};
  border: 1px solid
    ${(props) =>
      props.selected ? props.theme.primary : props.theme.nodeBorder};

  .react-flow__handle {
    background: ${(props) => props.theme.primary};
    width: 8px;
    height: 10px;
    border-radius: 3px;
  }
`;

export default memo(({ data, selected, xPos, yPos }) => {
  const [caption, setCaption] = React.useState(data.caption);
  const handleChange = (event) => {
    setCaption(event.target.value);
    data.caption = event.target.value;
  };
  const [image, setImage] = React.useState(data.image);
  const [progress, setProgress] = React.useState(0);

  const generate = () => {
    const startmessage = { session_hash: "", fn_index: 3 };
    const ws = new WebSocket(
      "wss://stabilityai-stable-diffusion.hf.space/queue/join"
    );
    ws.onopen = () => {
      ws.send(JSON.stringify(startmessage));
    };
    ws.onmessage = (e) => {
      const message = JSON.parse(e.data);
      if (message["msg"] === "send_data") {
        setProgress((oldProgress) => {
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
        const message = {
          session_hash: "",
          fn_index: 3,
          data: [caption, "", 9],
        };
        ws.send(JSON.stringify(message));
      } else if (message["msg"] === "process_completed") {
        console.log(message["output"]["data"][0][0]);
        setImage(message["output"]["data"][0][0]);
        data.image = message["output"]["data"][0][0];
        setProgress(100);
        ws.close();
      } else {
        setProgress((oldProgress) => {
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
        // console.log(message);
      }
    };
  };
  const prediction = () => {
    const canvas = document.getElementById("canvas");
    $.ajax({
      type: "POST",
      url: "http://" + document.location.hostname + ":5000/predict",
      data: JSON.stringify({
        sentences: caption,
      }),
      contentType: "application/json",
      dataType: "json",
      success: (data) => {
        const SIZE = 256;
        const context = canvas.getContext("2d");
        let $preview = document.querySelector("#preview");
        let pctx = $preview.getContext("2d");
        let res = Uint8ClampedArray.from(data["data"], (x) => x);
        $preview.width = 0;
        $preview.width = SIZE;
        $preview.height = SIZE;
        let resImgData = new ImageData(res, SIZE, SIZE);
        pctx.putImageData(resImgData, 0, 0);
        context.drawImage(
          $preview,
          0,
          0,
          $preview.width,
          $preview.height,
          0,
          0,
          canvas.width,
          canvas.height
        );
      },
    });
  };

  return (
    <div className="wrapper gradient">
      <Node selected={selected}>
        <NodeToolbar isVisible={data.toolbarVisible} position={Position.Bottom}>
          <TextField
            fullWidth
            id="outlined-textarea"
            label="Input Text Prompt"
            placeholder="Placeholder"
            multiline
            value={caption}
            onChange={handleChange}
          />
          <Button onClick={generate}>Generate</Button>
        </NodeToolbar>
        <Handle type="target" position="left" />
        {/* <div>
        <strong>{data.label}</strong>
      </div> */}
        <CardMedia
          component="img"
          height="256"
          width="256"
          // image={image}
          src={image}
          alt="green iguana"
          //   onClick={handleTransform}
        />
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate"  color="success" value={progress} />
        </Box>
        {/* <CardMedia image='https://images.unsplash.com/photo-1551963831-b3b1ca40c98e'></CardMedia> */}
        <Handle type="source" position="right" />
      </Node>
    </div>
  );
});
